import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button, FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
// import _ from "lodash";
import * as Yup from "yup";
import { debounce } from "lodash";
import regex from "../../utils/regex";
import { frequencyList, liabilitiesTypesList, validFrequencyList, validLiabilityType } from "../../constants";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import {
  saveValue,
  // deleteFinanceItem,
  createLiabilities,
  deleteLiabilities,
  updateLiabilities,
  // updateLiabilitiesList,
  userDetailsSelector,
  setRequiredFieldsErrors,
} from "../../store/slices/applicationFormSlice";
import { customerSelector } from "../../store/slices/customerSlice";

export default function Liabilities({ liabilities, customerId, applicationId, personalFinLoaders, customerIndex }) {
  const dispatch = useDispatch();
  const { isCustomerLogin = false } = useSelector(customerSelector);

  // const [newLiabilityName, setNewLiabilityName] = useState("");
  const [liabilityList, setLiabilityList] = useState(liabilities || []);
  const { requireFieldErrors } = useSelector(userDetailsSelector);
  // const { consumerFieldErrors } = lenderRequiredFieldsError;
  // const [liabilityFieldErrors, setLiabilityFieldErrors] = useState({
  //   liabilitiesType: "",
  //   outstandingAmount: "",
  //   limit: "",
  //   financier: "",
  //   repaymentFrequency: "",
  //   repaymentAmount: "",
  //   description: "",
  // });

  useEffect(() => {
    if (liabilities?.length > 0) {
      setLiabilityList(liabilities)
    }
  }, [liabilities])

  // useEffect(() => {
  //   if (consumerFieldErrors?.liabilities) {
  //     setLiabilityFieldErrors({
  //       liabilitiesType: consumerFieldErrors?.liabilities?.liabilitiesType || "",
  //       outstandingAmount: consumerFieldErrors?.liabilities?.outstandingAmount || "",
  //       limit: consumerFieldErrors?.liabilities?.limit || "",
  //       financier: consumerFieldErrors?.liabilities?.financier || "",
  //       repaymentFrequency: consumerFieldErrors?.liabilities?.repaymentFrequency || "",
  //       repaymentAmount: consumerFieldErrors?.liabilities?.repaymentAmount || "",
  //       description: consumerFieldErrors?.liabilities?.description || "",
  //     });
  //   }
  // }, [consumerFieldErrors?.liabilities]);

  const [changeDetected, setChangeDetected] = useState(false);
  const isAddLiabilityLoader = personalFinLoaders?.addLiabilityLoader && personalFinLoaders?.id === customerId;

  const validationSchema = Yup.object({
    liabilitiesType: Yup.string()
      .oneOf(validLiabilityType, "Please select valid liability type")
      .required("Liability type is required"),
    outstandingAmount: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    limit: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    financier: Yup.string().required("Financier is required"),
    repaymentFrequency: Yup.string()
      .oneOf(validFrequencyList, "Please select valid frequency")
      .required("Frequency is required"),
    repaymentAmount: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers allowed.")
      .max(10, "Maximum of 7 digits"),
    description: Yup.string().required("Description is required"),
  });

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        const field = fieldName.split("_")[0];
        await validationSchema.validateAt(field, { [field]: value });
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: ""
        }))
      } catch (error) {
        dispatch(setRequiredFieldsErrors({
          ...requireFieldErrors,
          [fieldName]: ""
        }))
      }
    }, 300),
    [requireFieldErrors]
  );

  // const validateField = async (fieldName, value) => {
  //   try {
  //     const field = fieldName.split("_")[0];
  //     await validationSchema.validateAt(field, { [field]: value });
  //     // setLiabilityFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //     dispatch(setRequiredFieldsErrors({
  //       ...requireFieldErrors,
  //       [fieldName]: ""
  //     }))
  //   } catch (error) {
  //     // setLiabilityFieldErrors((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   [fieldName]: error.message,
  //     // }));
  //     dispatch(setRequiredFieldsErrors({
  //       ...requireFieldErrors,
  //       [fieldName]: ""
  //     }))
  //   }
  // };

  const onAddNewLiabilitiesItem = () => {
    const data = {
      liabilitiesType: "",
      outstandingAmount: "",
      limit: "",
      financier: "",
      repaymentFrequency: "",
      repaymentAmount: "",
      description: "",
      application: applicationId,
      customer: customerId,
    };

    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: customerId,
        },
      }),
    );
    dispatch(createLiabilities(data));
  };

  const removeLiabilities = (Element) => {
    const data = {
      liabilityId: Element._id,
      application: Element.application,
      customer: Element.customer,
    };
    delete data._id;
    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: Element._id,
        },
      }),
    );
    dispatch(deleteLiabilities(data));
  };

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...liabilityList];

      temp.map((ele) => {
        // if (!ele._id) {
        //   console.log("ele", ele);
        // }
        if (ele._id === newID) {
          let updatedLiabilitiesData = {
            ...ele,
            [name]: value,
            liabilityId: newID,
            application: applicationId,
            customer: customerId,
          };
          delete updatedLiabilitiesData._id;
          if (changeDetected) {
            dispatch(updateLiabilities(updatedLiabilitiesData));
            setChangeDetected(false);
          }
          return updatedLiabilitiesData;
        } else {
          return ele;
        }
      });
    },
    onChange: async (field, value, newID, index) => {
      let name = field.split("_")[0];
      // await validateField(field, value);
      debouncedValidation(field, value);

      if (field.includes("liability_description")) {
        name = "description"
      }

      const temp = [...liabilityList];

      let isChangeDetected = false;

      const updateAssetType = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          isChangeDetected = true;
          dispatch(createLiabilities(dataWithOutId));
        }
        if (ele._id === newID) {
          const updatedLiabilitiesData = {
            ...ele,
            [name]: value,
          };
          if (ele[name] !== value) {
            isChangeDetected = true;
          }
          // validateField(name, value);
          return updatedLiabilitiesData;
        } else {
          return ele;
        }
      });
      setChangeDetected(isChangeDetected);
      if (isChangeDetected) {
        setLiabilityList(updateAssetType)
        // dispatch(updateLiabilitiesList(updateAssetType));
      }
    },
    // liabilities: (update) => {
    //   dispatch(updateLiabilities(update));
    // },
    // createLiability: async () => {
    //   try {
    //     if (fieldErrors["liabilitiesType"] === "") {
    //       await dispatch(
    //         createFinanceItem({
    //           data: {
    //             name: _.camelCase(newLiabilityName),
    //             label: newLiabilityName,
    //             liabilitiesId: liabilities._id,
    //           },
    //           endpoint: "liabilities",
    //         }),
    //       ).unwrap();
    //       setNewLiabilityName("");
    //     }
    //   } catch (error) {
    //     const newErrors = {};
    //     error.inner.forEach((validationError) => {
    //       newErrors[validationError.path] = validationError.message;
    //     });
    //     setLiabilityFieldErrors(newErrors);
    //   }
    // },
    // deleteLiability: async (data) => {
    //   await dispatch(
    //     deleteFinanceItem({
    //       data: { liabilityId: data.itemId, liabilitiesId: liabilities._id },
    //       endpoint: "liabilities",
    //     }),
    //   ).unwrap();
    // },
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold" }}
        style={{ marginBottom: "5px", marginTop: "20px", color: "#1d4164" }}
      >
        Liabilities
      </Typography>
      {liabilityList?.map((ele, index) => (
        <Grid container item spacing={2} style={{ marginBottom: "10px" }} alignItems="center" key={ele?._id}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="years-at-label"
                  error={requireFieldErrors[`liabilitiesType_${customerId}_${index}`]}
                >
                  Liabilities Type
                </InputLabel>
                <Select
                  size="small"
                  labelId="years-at-label"
                  id="demo-simple-select"
                  value={ele?.liabilitiesType}
                  label="Liabilities Type"
                  name={`liabilitiesType_${customerId}_${index}`}
                  onChange={(event) => handle.onChange(`liabilitiesType_${customerId}_${index}`, event.target.value, ele?._id, index)}
                  onBlur={() => handle.onBlur("liabilitiesType", ele?.liabilitiesType, ele?._id)}
                  error={requireFieldErrors[`liabilitiesType_${customerId}_${index}`]}
                >
                  {liabilitiesTypesList.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
                {requireFieldErrors[`liabilitiesType_${customerId}_${index}`] && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>{requireFieldErrors[`liabilitiesType_${customerId}_${index}`]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                id="outlined-basic"
                // type="tel"
                fullWidth
                type="text"
                inputProps={{ min: 0 }}
                label="Outstanding Amount"
                variant="filled"
                value={ele?.outstandingAmount}
                name={`outstandingAmount_${customerId}_${index}`}
                error={requireFieldErrors[`outstandingAmount_${customerId}_${index}`]}
                helperText={requireFieldErrors[`outstandingAmount_${customerId}_${index}`]}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                onChange={(event) =>
                  handle.onChange(`outstandingAmount_${customerId}_${index}`, currencyMaskedValue(event.target.value), ele?._id, index)
                }
                onBlur={() => handle.onBlur("outstandingAmount", ele?.outstandingAmount, ele?._id)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                id="outlined-basic"
                // type="tel"
                type="text"
                inputProps={{ min: 0 }}
                label="Limit"
                variant="filled"
                style={{ width: "100%" }}
                value={ele?.limit}
                name={`limit_${customerId}_${index}`}
                error={requireFieldErrors[`limit_${customerId}_${index}`]}
                helperText={requireFieldErrors[`limit_${customerId}_${index}`]}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                onChange={(event) => handle.onChange(`limit_${customerId}_${index}`, event.target.value, ele?._id, index)}
                onBlur={() => handle.onBlur("limit", currencyMaskedValue(ele?.limit), ele?._id)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                id="outlined-basic"
                type="text"
                label="Financier"
                variant="filled"
                style={{ width: "100%" }}
                value={ele?.financier}
                name={`financier_${customerId}_${index}`}
                error={requireFieldErrors[`financier_${customerId}_${index}`]}
                helperText={requireFieldErrors[`financier_${customerId}_${index}`]}
                onChange={(event) => handle.onChange(`financier_${customerId}_${index}`, event.target.value, ele?._id, index)}
                onBlur={() => handle.onBlur("financier", ele?.financier, ele?._id)}
              />
            </Grid>

            {/* <Grid container item spacing={1} alignItems="center" xs={12}> */}
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="years-at-label"
                  error={requireFieldErrors[`repaymentFrequency_${customerId}_${index}`]}
                >
                  Repayment Frequency
                </InputLabel>
                <Select
                  size="small"
                  labelId="years-at-label"
                  id="demo-simple-select"
                  value={ele?.repaymentFrequency}
                  label="Repayment Frequency"
                  name={`repaymentFrequency_${customerId}_${index}`}
                  onChange={(event) => handle.onChange(`repaymentFrequency_${customerId}_${index}`, event.target.value, ele?._id, index)}
                  onBlur={() => handle.onBlur("repaymentFrequency", ele?.repaymentFrequency, ele?._id)}
                  error={requireFieldErrors[`repaymentFrequency_${customerId}_${index}`]}
                >
                  {frequencyList.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
                {requireFieldErrors[`repaymentFrequency_${customerId}_${index}`] && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>{requireFieldErrors[`repaymentFrequency_${customerId}_${index}`]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="outlined-basic"
                // type="tel"
                type="text"
                inputProps={{ min: 0 }}
                label="Repayment amount"
                variant="filled"
                size="small"
                style={{ width: "100%" }}
                value={ele?.repaymentAmount}
                name={`repaymentAmount_${customerId}_${index}`}
                error={requireFieldErrors[`repaymentAmount_${customerId}_${index}`]}
                helperText={requireFieldErrors[`repaymentAmount_${customerId}_${index}`]}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                onChange={(event) =>
                  handle.onChange(`repaymentAmount_${customerId}_${index}`, currencyMaskedValue(event.target.value), ele?._id, index)
                }
                onBlur={() => handle.onBlur("repaymentAmount", ele?.repaymentAmount, ele?._id)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                id="outlined-basic"
                type="text"
                label="Description"
                variant="filled"
                style={{ width: "100%", flex: "1" }}
                value={ele?.description}
                name={`liability_description_${customerId}_${index}`}
                error={requireFieldErrors[`liability_description_${customerId}_${index}`]}
                helperText={requireFieldErrors[`liability_description_${customerId}_${index}`]}
                onChange={(event) => handle.onChange(`liability_description_${customerId}_${index}`, event.target.value, ele?._id, index)}
                onBlur={() => handle.onBlur("description", ele?.description, ele?._id)}
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={1}>
            {!isCustomerLogin && liabilityList?.length !== 0 && index !== 0 && (
              <Button
                size="small"
                style={{ marginTop: "10px" }}
                onClick={() => removeLiabilities(ele)}
                variant="outlined"
                disabled={personalFinLoaders?.removeLiabilityLoader && personalFinLoaders?.id === ele?._id}
              >
                {personalFinLoaders?.removeLiabilityLoader && personalFinLoaders?.id === ele?._id
                  ? "Removing..."
                  : "Remove"}
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <div>
          <Button
            onClick={() => onAddNewLiabilitiesItem()}
            variant="outlined"
            size="small"
            disabled={
              isAddLiabilityLoader ||
              (!liabilityList?.[0]?.liabilitiesType &&
                !liabilityList?.[0]?.description &&
                !liabilityList?.[0]?.repaymentAmount &&
                !liabilityList?.[0]?.repaymentFrequency &&
                !liabilityList?.[0]?.limit &&
                !liabilityList?.[0]?.outstandingAmount &&
                !liabilityList?.[0]?.financier
                ? true
                : false)
            }
          >
            {isAddLiabilityLoader ? "Adding..." : "Add liability"}
          </Button>
        </div>
      </Grid>
    </>
  );
}
